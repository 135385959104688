<template>
  <div class="renew-membership-page">
    <Backbar
      :title="$t('renewPage.title')"
      @onBackClick="$router.go(-1)"
    />
    <div class="content">
      <RenewTh v-if="$i18n.locale === 'th'"/>
      <RenewEn v-else-if="$i18n.locale === 'en'"/>
    </div>
    <div class="is-flex justify-center action-section">
      <RoundButton @click="renew()" :disabled="!isAbleToRenew">{{$t('renewPage.accept')}}</RoundButton>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mapWaitingActions } from 'vue-wait'

import Backbar from '@/components/Backbar.vue'
import RoundButton from '@/components/base/RoundButton.vue'

import RenewTh from '@/components/renew/RenewTh.vue'
import RenewEn from '@/components/renew/RenewEn.vue'

export default {
  name: 'RenewMembership',
  components: {
    Backbar,
    RoundButton,
    RenewTh,
    RenewEn
  },
  computed: {
    ...mapGetters('Auth', [
      'isAbleToRenew'
    ])
  },
  methods: {
    ...mapActions({
      renewMember: 'Auth/renewMember',
      fetchCustomer: 'Auth/fetchCustomer'
    }),
    ...mapWaitingActions('Auth', {
      renewMember: 'app loading',
      fetchCustomer: 'app loading'
    }),
    async renew () {
      try {
        await this.renewMember({
        })
        await this.fetchCustomer()
        this.$buefy.toast.open({
          message: 'ต่ออายุบัตรสมาชิกสำเร็จ',
          position: 'is-bottom',
          type: 'is-success'
        })
      } catch (error) {
        if (error.response && error.response.status === 400) {
          const { data } = error.response
          this.errorMessage = data
        } else {
          this.errorMessage = error.toString()
        }
        this.$buefy.toast.open({
          message: this.errorMessage,
          position: 'is-bottom',
          type: 'is-danger'
        })
      } finally {
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.renew-membership-page {
  padding-bottom: 100px;
  .content {
    padding: 18px;
  }
  .action-section {
    position: fixed;
    bottom: 0;
    padding: 20px;
    width: 100%;
    background: white;
    z-index: 3;
  }
}
</style>
